import * as React from 'react';
import { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';

import MuiLink from '@mui/material/Link';

import { Link } from "react-router-dom";

import { useCookies } from 'react-cookie';

import { UserContext } from './../hooks/UserContext';

//const pages = ['Home', 'Rezepte', 'Upload'];
const pages = [{ name: 'Home', link: '/' },
{ name: 'Rezepte', link: '/recipes' },
{ name: 'Upload', link: '/upload' }];



const ResponsiveAppBar = () => {
  //const { setAuth } = useAuth();
  const [, , removeCookie] = useCookies(['token']);
  const { user } = useContext(UserContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    //setAuth({username:'',password:'',token:''})
    removeCookie('login')
    removeCookie('token')
    window.location = '/'
  }

  return (

    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <RestaurantOutlinedIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            REZEPTE
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <MuiLink textAlign="center" component={Link} to={page.link} underline="none" color="inherit" >{page.name}</MuiLink>
                </MenuItem>
              ))}
              {/** 
              <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={ (e) => {search(e)}}
              value={value}
            />
          </Search>*/}
            </Menu>
          </Box>

          <RestaurantOutlinedIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            REZEPTE
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
                component={Link} to={page.link}
              >
                {page.name}
              </Button>
            ))}
          </Box>
          {/**
          <Box sx={{ flexDirection: 'row-reverse',flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
 
          <Search >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={ (e) => {search(e)}}
              value={value}
            />
          </Search>

          </Box>*/}


          {user ?
            <Box sx={{ flexGrow: 0 }}>
              {/** 
              <Stack direction="row" spacing={2}>
                <Container>
              <Box  component='span' sx={{display:{xs:'none',md:'block'},verticalAlign:'middle', margin:'auto',}}>{user.username}</Box>
              </Container>*/}
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {/*<Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />*/}
                  <AccountCircleOutlinedIcon fontSize="large" sx={{ color: '#ffffff' }} />
                </IconButton>
              </Tooltip>
              {/** </Stack>*/}
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >

                <MenuItem key='logout' onClick={handleCloseUserMenu}>
                  <Typography textAlign="center" onClick={logout}>Logout</Typography>
                </MenuItem>
                <MenuItem key='admin' onClick={handleCloseUserMenu}>
                  <MuiLink textAlign="center" component={Link} to='/admin' underline="none" color="inherit">Admin</MuiLink>
                </MenuItem>
                <MenuItem key='profile' onClick={handleCloseUserMenu}>
                  <MuiLink textAlign="center" component={Link} to='/profile' underline="none" color="inherit">Profil</MuiLink>
                </MenuItem>
              </Menu>
            </Box> : <Box>&nbsp;&nbsp;<MuiLink textAlign="center" component={Link} to='/login' underline="none" color="inherit">Login</MuiLink></Box>}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
