import { useState, useEffect } from 'react';
import axios from 'axios'; 
import { useCookies } from 'react-cookie';

import {BASE_URL} from '../api'

export default function useFindUser() {
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [cookies] = useCookies(['cookie-name']);
    useEffect(() =>{
        async function findUser() {
            //console.log(cookies['token'])
        await axios.post(BASE_URL+'/user/x/user',{
            tkn:cookies['token']
        })
        .then(res => {
            setUser(res.data.currentUser);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
        }
        
        findUser();  
    }, [cookies]);
    
    return {
        user,
        setUser,
        isLoading
    }
}