import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function Tags(props) {

    return (

      <Autocomplete
        fullWidth
        freeSolo
        name='type'
        /*
        onChange={(event, newValue) => {
          //setValue(newValue);
          event.preventDefault();
          event.stopPropagation();
          props.parentCallback(props.index, newValue._id)
          console.log(newValue)
        }}*/ 
        onInputChange={(event,newValue)=>{
          props.parentCallback(props.index, newValue)
          try{
            event.preventDefault();
            event.stopPropagation();
          }catch (e) {
            //console.log(null)
          }
          
        }}
        //value={props.value}
        id="tags-standard"
        options={props.items}
        getOptionLabel={(option) => option || ''}
        defaultValue={props.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Zutat' //handle required mark(*) on label 
            required
            variant="outlined"

            //label="Kategorie *"
            placeholder="Name"
          />
        )}

      />


    );
  

}

