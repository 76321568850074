import { useState, useContext } from 'react'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './UserContext';  
import { useCookies } from 'react-cookie';

import {BASE_URL} from '../api'

export default function useAuth() {
    let navigate = useNavigate();
    const { setUser } = useContext(UserContext);
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [cookies,setCookie] = useCookies(['token']);
    //set user
    let d = new Date();
    d.setTime(d.getTime() + (30*24*60*60*1000));
    cookies.token = null


    const setUserContext = async (tkn,type) => {
        return await axios.post(BASE_URL+'/user/x/user',{
            tkn
        }
        ).then(res => {
            setCookie('token', tkn, { path: '/' , expires: d});          
            setUser(res.data.currentUser); 
            if(type === 'login'){
                navigate('/upload');   
            }else{
                navigate('/home');   
            }
            //navigate('/upload');                     
            }).catch((err) => {
                setErrorMessage(err.response.data);
                setError(true)
        })
    }

    //register user  
    const registerUser = async (data) => {
        //console.log(data);
        const { username, email, password, passwordConfirm } = data;
            return axios.post(BASE_URL+'/user/x/register', {
                  username,
                  email,
                  password,
                  passwordConfirm
                }).then(async () => {
                    await setUserContext();
                })
                .catch((err) => {
                    setErrorMessage(err.response.data);
                    return setError(true)
                    
            })
        };

    //login user 
    const loginUser = async (data,type) => {
        const { username, password } = data;
            return axios.post(BASE_URL+'/user/x/login', {
                username,
                password,
            }).then(async (res) => {
                //console.log(res.data.token)
                await setUserContext(res.data.token,type);
            }).catch((err) => {
                console.log(err)
                setErrorMessage(err.response.data);
                setError(true)
            })
        };

        const changePw = async (data,type) => {
            const { username, password, newPassword,newPasswordConfirm } = data;
                return axios.post(BASE_URL+'/user/x/changepw', {
                    username,
                    password,
                    newPassword,
                    newPasswordConfirm
                }).then(async (res) => {
                    //console.log(res.data.token)
                    await setUserContext(res.data.token,type);
                }).catch((err) => {
                    console.log(err)
                    setErrorMessage(err.response.data);
                    setError(true)
                })
            };

    return {
        registerUser,
        loginUser,
        changePw,
        error,
        errorMessage
    }
}