import React from 'react'
import axios from 'axios';

import Chip from '@mui/material/Chip';

import Container from '@mui/material/Container';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from "react-router-dom";
import SearchInput from '../components/SearchInput';
import SearchIcon from '@mui/icons-material/Search';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



import { BASE_URL } from '../api'

import IngredientsSearch from "../components/IngredientsSearch"

export default function Home() {

  let navigate = useNavigate();

  const [meta, setMeta] = React.useState([])
  const [categories, setCategories] = React.useState([])
  const [ingredients, setIngredients] = React.useState([])

  const [searchValue, setSearch] = React.useState(null)
  const [ingredientValue, setIngredientValue] = React.useState(null)


  React.useEffect(() => {


      axios.get(BASE_URL + '/config/start')
      .then(res => {
        const ingredients = res.data.ingredients;
        setIngredients(ingredients);
        const categories = res.data.categories;
        setCategories(categories);
        const meta = res.data.meta;
        setMeta(meta);
        console.log(res.data)
      })



  }, [])

  const ingredientsTags = () => {
    const res = [];
    const chunkSize = ingredients.length / 10
    for (let i = 0; i < ingredients.length; i += chunkSize) {
      const chunk = ingredients.slice(i, i + chunkSize);
      res.push(chunk);

    }
    return res;
  }
  const metaTags = () => {
    const res = [];
    const chunkSize = meta.length / 10
    for (let i = 0; i < meta.length; i += chunkSize) {
      const chunk = meta.slice(i, i + chunkSize);
      res.push(chunk);

    }
    return res;
  }
  const splitIngredients = ingredientsTags()
  const splitMeta = metaTags()


  const searchHandler = (e) => {
    e.preventDefault()
    if (typeof searchValue == 'string') {
      navigate('/search/' + searchValue)
    } else {
      navigate('/recipe/' + searchValue._id)
    }
    //navigate('/search/'+searchValue)
    console.log(searchValue)
  }


  const ingredientHandler = (e) => {
    e.preventDefault()
    let a = []
    ingredientValue.map(i=>
      a.push(i._id)
      )
    navigate('/find/'+a)
  }


  document.title = 'Rezepte'

  return (

    <div>
      <Container sx={{ width: '95%' ,mb:10}} lg={{ width: '60%',mb:5 }}>


        <div>
          <br></br><br></br>

          <Stack direction="row" spacing={2}>

            <SearchInput callback={setSearch} />
            {/** 
        <Button variant='outlined' onClick={searchHandler}>
        <SearchIcon />
        </Button>
        */}
            <IconButton variant='outlined' onClick={(e) => searchHandler(e)} aria-label="search" size="big" color='primary'>
              <SearchIcon fontSize="inherit" />
            </IconButton>
          </Stack>

          <p><br></br></p><p><br></br></p>
          <Divider>Kategorien</Divider>
          <br></br>
          {categories.map(cat =>

            <Chip sx={{ margin: '5px', fontSize: '20px', padding: '15px 5px' }} key={cat} label={cat} variant="outlined" component={Link} to={'category/' + cat} clickable></Chip>

          )}

        </div>
        <p><br></br></p>
        <div>
          <Divider>Tags</Divider>
          <br></br>

          {
            splitMeta.map((item, index) =>

              item.map(meta =>

                <Chip sx={{ margin: '5px', fontSize: (10 - 1.2 * index) + 15 + 'px', height: (10 - 1.2 * index) + 25 + 'px', padding: '15px 5px' }} key={meta._id} label={meta._id} variant="outlined" component={Link} to={'meta/' + meta._id} clickable></Chip>

              )
            )
          }

        </div>
        <p><br></br></p>
        <div>
          <Divider>Zutaten</Divider>
          <br></br>


          <Stack direction="row" spacing={2}>

          <IngredientsSearch callback={setIngredientValue}/>
<IconButton variant='outlined' onClick={(e) => ingredientHandler(e)} aria-label="ingredient-search" size="big" color='primary'>
  <SearchIcon fontSize="inherit" />
</IconButton>
</Stack>



<br></br>

<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="zutaten-content"
          id="alle-zutaten"
        >
          <Typography>Alle Zutaten</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {
            splitIngredients.map((item, index) =>

              item.map(ingredient =>

                <Chip size='100' sx={{ margin: '5px', fontSize: (10 - 1.2 * index) + 15 + 'px', height: (10 - 1.2 * index) + 25 + 'px', padding: '15px 5px' }} key={ingredient._id} label={ingredient._id} variant="outlined" component={Link} to={'ingredient/' + ingredient._id} clickable></Chip>

              )
            )
          }
        </AccordionDetails>
      </Accordion>


          

        </div>

      </Container>
    </div>

  )




}