import React from 'react';

import useAuth from './../hooks/useAuth';


import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';


import { Navigate } from "react-router-dom";

import { UserContext } from './../hooks/UserContext';
import Loading from './../components/Loading';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';



export default function Login() {


  const { loginUser, error } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    await loginUser({ username, password }, 'login');
  }
  const [username, setUserName] = React.useState();
  const [password, setPassword] = React.useState();

  const { user, isLoading } = React.useContext(UserContext);
  //console.log(user, isLoading);


  if (isLoading) {
    return <Loading />
  }
  /*
        if(user){
          return ( <Route {...rest} render={(props) => (<Component {...props}/>)}/>)
          } else {
            return <Navigate to='/login'/> 
          }*/
  return (user ? <Navigate to="/" /> :




    <Container>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '50vh' }}
      >

        <Grid item xs={3}>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleLogin}
          >
            <Stack direction="column" spacing={2}>
              <TextField id="outlined-basic" label="Username" variant="outlined" required onChange={e => setUserName(e.target.value)} />

              <TextField id="filled-basic" label="Passwort" variant="outlined" required type="password" onChange={e => setPassword(e.target.value)} />
              <Collapse in={error}>
                <Alert open={error} severity="error">Versuch es noch einmal!</Alert>
              </Collapse>
              <Button variant="outlined" type="submit">Login</Button>
            </Stack>
          </Box>
        </Grid>

      </Grid>
    </Container>


  )
}