//import React from 'react'
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import RecipeCard from '../components/RecipeCard';
import {
  useParams
} from 'react-router-dom';

import { BASE_URL } from '../api'


export default function Ingredient() {
  const param = useParams().ingr;
  //const [category, setCategory] = React.useState('');
  //setCategory(param)
  //console.log(param)
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [pages, setPages] = React.useState(5);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    fetch(BASE_URL + "/recipes/ingredient/" + param + '/' + page)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [page, param])

  React.useEffect(() => {
    fetch(BASE_URL + "/recipes/count/ingredient/" + param + "/")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setCount(result.count)
          if (result.count % 20 !== 0) {
            const pageCount = Math.ceil(result.count / 20);
            setPages(pageCount)

          } else {
            const pageCount = result.count / 20;
            setPages(pageCount);

          }

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setPage(5);
        }
      )
  }, [items, param])
  const handlePage = (event, page) => {
    setPage(page)
    //console.log(page)
  }

  const pagination = (pages) => {

    if (pages > 1) {


      return (

        <Container>
          <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <Pagination count={pages} page={page} onChange={handlePage} />
          </Grid>
        </Container>


      )
    }
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (

      <div>


        {count ?

          <Container sx={{ width: '95%' }} lg={{ width: '60%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 9, md: 12 }}>


              {items.map(recipe =>
                <RecipeCard key={recipe._id} recipe={recipe}></RecipeCard>

              )}



            </Grid>
          </Container> :

          <Container>
            <br></br>
            <br></br>
            <Typography variant="h4">
              No Results
            </Typography>
          </Container>}


        <p></p>

        {pagination(pages)}


      </div>
    )
  }
}