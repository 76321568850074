//import React from 'react'
import * as React from 'react';
import axios from 'axios';
import { useContext } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AdminTable from '../components/AdminTable';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useCookies } from 'react-cookie';
import { UserContext } from './../hooks/UserContext';
import { BASE_URL } from '../api'

export default function Admin() {
  document.title = 'Admin'
  const [cookies] = useCookies(['cookie-name']);
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const { user, isLoading } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [delId, setDelId] = React.useState('');
  const [deleted, setDeleted] = React.useState('');
  const handleClickOpen = (event, id, name) => {
    setOpen(true);
    setTitle(name)
    setDelId(id)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (event, id) => {
    event.preventDefault();
    const config = {
      headers: {
        authorization: 'Bearer ' + cookies['token']
      }
    };
    //event.preventDefault();
    //navigate("/recipe/"+name, { replace: false });
    //console.log(id)
    //handleClose()
    axios.delete(BASE_URL + '/recipes/delete/' + id, config).then(response => {
      //console.log(response)
      if (response.status === 200) {
        handleClose()
      }
    })
      .catch((err) => {

        console.log(err)

      }).finally(() => {

        setDeleted(delId)

      }

      );
  };

  React.useEffect(() => {

    if (!isLoading) {




      fetch(BASE_URL + "/recipes/all/user/" + user.username)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);
            //console.log(+result.length)
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )

    }

  }, [deleted, isLoading, user])






  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {

    return (
      <>
        <Container sx={{ width: '95%' }} lg={{ width: '60%' }}>
          <Typography variant="h4">Rezepte</Typography>
          <p></p>
          <AdminTable rows={items} handleClickOpen={handleClickOpen}></AdminTable>


          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Sind Sie sicher?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  M&ouml;chten Sie {title} wirklich l&ouml;schen!<br />
                  Dieser Vorgang kann nicht r&uuml;ckg&auml;ngig gemacht werden!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={(event) => handleDelete(event, delId)}>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </div>

        </Container>
      </>

    )
  }
}