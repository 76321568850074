//import React from 'react'
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@mui/material/IconButton'

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';


import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';


import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ReactToPrint from "react-to-print";
import {
  Link,
  useParams
} from 'react-router-dom';
import { Button } from '@mui/material';

import { BASE_URL } from '../api'

function image(img) {
  if (img !== '' && img !== undefined) {
    return (
      <Box align="center">
        <Box
          component="img"
          sx={{
            width: '100%',
            //height: {xs:200,md:400},
            //width: auto,
            maxHeight: { xs: 250, md: 600 },
            maxWidth: { xs: 350, md: 1000 },
          }}
          alt={'Image ' + img}
          //src={'/images/' + img}
          src={BASE_URL + '/images/download/' + img}
        />
      </Box>
    )
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'initial':
      return { initialIngr: action.payload.ingr, changedIngr: action.payload.ingr, initialCount: action.payload.count, changedCount: action.payload.count };
    case 'increment':
      const result1 = state.changedIngr
      console.log(state)
      state.changedIngr.map((value, index) => {
        result1[index].amount = Math.round(((action.payload.ingr[index].amount / state.initialCount) * action.payload.count) * 1000) / 1000;
        return null
      })
      return { initialIngr: state.initialIngr, changedIngr: result1, initialCount: state.initialCount, changedCount: action.payload.count };
    case 'decrement':
      if (state.changedCount > 1) {
        const result2 = state.initialIngr
        console.log(state)
        state.changedIngr.map((value, index) => {
          result2[index].amount = Math.round(((action.payload.ingr[index].amount / state.initialCount) * action.payload.count) * 1000) / 1000;
          return null
        })
        return { initialIngr: state.initialIngr, changedIngr: result2, initialCount: state.initialCount, changedCount: action.payload.count };
      } else return { initialIngr: state.initialIngr, changedIngr: state.changedIngr, initialCount: state.initialCount, changedCount: state.changedCount };
    default:
      throw new Error();
  }
}






export default function Recipe() {

  let componentRef = React.useRef();

  const param = useParams().id;
  //const [category, setCategory] = React.useState('');
  //setCategory(param)
  //console.log(param)
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [value, setValue] = React.useState([]);
  //const [ingredients, setIngredients] = React.useState([]);
  //const [portions, setPortions] = React.useState(0);

  const initialState = { initialIngr: [], changedIngr: [], initialCount: 0, changedCount: 0 };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  document.title = value.title
  const renderTable = () => {
    return (

      state.changedIngr.map((row, index) => {


        //console.log(row)
        if (row.type === "xxx") {
          return (
            <TableRow key={row.name} >


              <TableCell align="center" colSpan={3}>
                {row.name}
              </TableCell>
            </TableRow>
          )
        } else {
          return (
            <TableRow key={row.name} >
              <TableCell>{row.amount}</TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.name}</TableCell>

            </TableRow>
          )
        }





      }



      )
    )
  }







  React.useEffect(() => {
    fetch(BASE_URL + "/recipes/recipe/" + param)
      .then(res => res.json())
      .then(
        (result) => {

          setIsLoaded(true);
          setValue(result);
          const ingr = result.ingredients
          //setIngredients(ingr)

          //setPortions(parseInt(result.number))
          sessionStorage.setItem(param, JSON.stringify(result.ingredients));
          dispatch({ type: 'initial', payload: { ingr: ingr, count: parseInt(result.number) } })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [param])






  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {

    return (

      <div >


        <Container sx={{ width: '95%' }} lg={{ width: '60%' }} >
          <Box align="right">
            <ReactToPrint
              trigger={() => <Button>
                <PrintOutlinedIcon></PrintOutlinedIcon>
              </Button>}
              content={() => componentRef}
              pageStyle={'textAlign=center,padding=50px'}
              documentTitle={value.title.replace(' ', '-')}
            />
          </Box>
          <Box ref={(el) => (componentRef = el)}>
            <p></p>
            <Typography align="center" variant='h3' sx={{fontSize: {xs:'24px',md:'36px',lg:'42px'}}}>{value.title}</Typography>
            <p></p>
            {image(value.img)}


            <p></p>
            <Typography align="right" variant="body" className='print-source-show'>
              {state.changedCount}&nbsp;Portionen
            </Typography>
            <Typography variant="body" component='p' align="right">
              Dauer: {value.duration} Min.
            </Typography>
            <Divider>Zutaten</Divider>
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Menge</TableCell>
                    <TableCell >Einheit</TableCell>
                    <TableCell >Zutat</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>


                  {renderTable()}



                </TableBody>
              </Table>


              <p></p>

              <Tooltip title="Umrechnung bezieht sich nur auf die Tabelle, nicht auf die Zubereitung!">
                <Box align="center" className='print-source'>
                  <IconButton variant='outlined' onClick={() => dispatch({ type: 'decrement', payload: { count: state.changedCount - 1, ingr: JSON.parse(sessionStorage.getItem(param)) } })}>
                    <RemoveCircleOutlineOutlinedIcon color='primary' sx={{ fontSize: 28 }} />
                  </IconButton>
                  &nbsp;{state.changedCount}&nbsp;
                  <IconButton variant='outlined' onClick={() => dispatch({ type: 'increment', payload: { count: state.changedCount + 1, ingr: JSON.parse(sessionStorage.getItem(param)) } })}>
                    <AddCircleOutlineOutlinedIcon color='primary' sx={{ fontSize: 28 }} />
                  </IconButton>
                </Box>
              </Tooltip>


            </TableContainer>



            <p></p>
            <p></p>
            <Divider>Zubereitung</Divider>
            <Paper elevation={0} style={{
              padding: 12
            }}>
              <Typography variant="body" component='p' align="left" >
                {
                  value.preparation.split("\n").map(function (item, idx) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    )
                  })
                }


              </Typography>
            </Paper>

            <p></p>
            <p></p>
            {/** 
            <Typography variant="body" >
              Portionen: {value.number} P.
            </Typography>
            <p></p>
*/}


            {value.note ?
              <Box>
                <Divider>Notizen</Divider>
                <Paper elevation={0} style={{
                  padding: 12
                }}>
                  <Typography variant="body" component='p' align="left" >
                    {
                      value.note.split("\n").map(function (item, idx) {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        )
                      })
                    }


                  </Typography>
                </Paper>

              </Box> : ''

            }

            <Box align="center">
              <Divider>Kategorien</Divider>
              {value.categories.map(cat =>

                <Chip sx={{ margin: '5px' }} key={cat} label={cat} variant="outlined" component={Link} to={'/category/' + cat} clickable></Chip>

              )}
            </Box>
            {value.meta.length ?
              <Box align="center">
                <p></p>
                <Divider>Meta</Divider>
                {value.meta.map(meta =>

                  <Chip sx={{ margin: '5px' }} key={meta} label={meta} variant="outlined" component={Link} to={'/meta/' + meta} clickable></Chip>

                )
                }

              </Box>
              : ''

            }
          </Box>
        </Container>
        <p></p>
        <p></p>
      </div>
    )
  }
}