
import React, { useContext } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';
import MetaSelect from '../components/MetaSelect';
import CategorieSelect from '../components/CategorySelect';
import MeasurmentSelect from '../components/MeasurmentSelect';
import IngredientSelect from '../components/IngredientSelect';
import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import Divider from '@mui/material/Divider';

import { UserContext } from './../hooks/UserContext';

import { useCookies } from 'react-cookie';

import { BASE_URL } from '../api'

export default function Upload() {
  const [ingredientsError, setIngredientsError] = React.useState(null);
  const [isIngredientsLoaded, setIngredientsIsLoaded] = React.useState(false);
  const [ingredientsItems, setIngredientsItems] = React.useState([]);


  React.useEffect(() => {
    fetch(BASE_URL + "/config/ingredients/")
      .then(res => res.json())
      .then(
        (result) => {
          let res = result.map(a => a._id);
          setIngredientsIsLoaded(true);
          setIngredientsItems(res)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIngredientsIsLoaded(true);
          setIngredientsError(error);
        }
      )
  }, [])

  const [measurmentsError, setMeasurmentsError] = React.useState(null);

  const [isMeasuremntsLoaded, setMeasurmentsIsLoaded] = React.useState(false);
  const [measurmentsItems, setMeasurmentsItems] = React.useState([]);
  React.useEffect(() => {
    fetch(BASE_URL + "/config/measurments/")
      .then(res => res.json())
      .then(
        (result) => {
          setMeasurmentsIsLoaded(true);
          setMeasurmentsItems(result[0].data);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setMeasurmentsIsLoaded(true);
          setMeasurmentsError(error);
        }
      )
  }, [])



  const [cookies] = useCookies(['cookie-name']);
  const { user } = useContext(UserContext);
  const [state, setState] = React.useState({
    title: "",
    preparation: "",
    ingredients: [
      { name: "", amount: "", type: "" }
    ],
    meta: [],
    duration: 0,
    number: 0,
    owner: user.username,
    categories: [],
    note: '',
    img: '',
    imgName: ''
  })
  let navigate = useNavigate();
  const [fileData, setFileData] = React.useState();
  const [fileButton, setFileButton] = React.useState('Foto')
  const [inputFields, setInputFields] = React.useState([
    { name: '', type: '', amount: '' }
  ])
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
    //console.log(inputFields)
    setState(
      {
        title: state.title,
        preparation: state.preparation,
        ingredients: inputFields,
        meta: state.meta,
        duration: state.duration,
        number: state.number,
        owner: state.owner,
        categories: state.categories,
        note: state.note,
        img: state.img,
        imgName: state.imgName
      })
    //console.log(inputFields)
  }

  const handleTypeChange = (index, value) => {
    let data = [...inputFields];
    data[index]['type'] = value;
    setInputFields(data);
    //console.log(inputFields)
    setState(
      {
        title: state.title,
        preparation: state.preparation,
        ingredients: inputFields,
        meta: state.meta,
        duration: state.duration,
        number: state.number,
        owner: state.owner,
        categories: state.categories,
        note: state.note,
        img: state.img,
        imgName: state.imgName
      })
    //console.log(inputFields)
  }

  const handleNameChange = (index, value) => {
    let data = [...inputFields];
    data[index]['name'] = value;
    setInputFields(data);
    //console.log(inputFields)
    setState(
      {
        title: state.title,
        preparation: state.preparation,
        ingredients: inputFields,
        meta: state.meta,
        duration: state.duration,
        number: state.number,
        owner: state.owner,
        categories: state.categories,
        note: state.note,
        img: state.img,
        imgName: state.imgName
      })
    //console.log(inputFields)
  }

  const addFields = (e) => {
    e.preventDefault();
    let newfield = { name: '', type: '', amount: '' }

    setInputFields([...inputFields, newfield])
    setState(
      {
        title: state.title,
        preparation: state.preparation,
        ingredients: inputFields,
        meta: state.meta,
        duration: state.duration,
        number: state.number,
        owner: state.owner,
        categories: state.categories,
        note: state.note,
        img: state.img,
        imgName: state.imgName
      })
    //console.log(inputFields)
  }


  const addField0 = (e) => {
    e.preventDefault();
    let newfield = { name: '', type: 'xxx', amount: '0' }

    setInputFields([newfield, ...inputFields])
    setState(
      {
        title: state.title,
        preparation: state.preparation,
        ingredients: inputFields,
        meta: state.meta,
        duration: state.duration,
        number: state.number,
        owner: state.owner,
        categories: state.categories,
        note: state.note,
        img: state.img,
        imgName: state.imgName
      })
    //console.log(inputFields)
  }

  const addFieldx = (e) => {
    e.preventDefault();
    let newfield = { name: '', type: 'xxx', amount: '0' }

    setInputFields([...inputFields, newfield])
    setState(
      {
        title: state.title,
        preparation: state.preparation,
        ingredients: inputFields,
        meta: state.meta,
        duration: state.duration,
        number: state.number,
        owner: state.owner,
        categories: state.categories,
        note: state.note,
        img: state.img,
        imgName: state.imgName
      })
    //console.log(inputFields)
  }


  const removeFields = (e) => {
    e.preventDefault();

    if (inputFields.length > 1) {
      inputFields.pop()

      //setInputFields(data)
      setState(
        {
          title: state.title,
          preparation: state.preparation,
          ingredients: inputFields,
          meta: state.meta,
          duration: state.duration,
          number: state.number,
          owner: state.owner,
          categories: state.categories,
          note: state.note,
          img: state.img,
          imgName: state.imgName
        })
      //console.log(inputFields)
    }
  }


  const removeFieldx = (e, index) => {
    e.preventDefault();

    if (inputFields.length > 1) {
      //inputFields.pop()
      inputFields.splice(index, 1)
      //setInputFields(data)
      setState(
        {
          title: state.title,
          preparation: state.preparation,
          ingredients: inputFields,
          meta: state.meta,
          duration: state.duration,
          number: state.number,
          owner: state.owner,
          categories: state.categories,
          note: state.note,
          img: state.img,
          imgName: state.imgName
        })
      //console.log(inputFields)
    }
  }




  const renderIngredientInputs = () => {
    //console.log(state)
    if (measurmentsError || ingredientsError) {
      return <div>Error:<br></br> {measurmentsError.message} <br></br> {ingredientsError.message}</div>;
    } else if (!isIngredientsLoaded && isMeasuremntsLoaded) {
      //return <div>Loading...</div>;
      return <CircularProgress />;
    } else {
    return (
      inputFields.map((input, index) => {


        if (input.type === 'xxx') {
          return (
            <div key={index}>
              <Divider>


                <TextField sx={{
                  width: 200
                }} name="Name" required id="outlined-basic" label="Name" variant="outlined" value={input.name} onChange={event => handleNameChange(index, event.target.value)} />
                <IconButton variant="outlined" onClick={event => removeFieldx(event, index)} aria-label="delete"><DeleteIcon color="primary" sx={{ fontSize: 30 }} /></IconButton>


              </Divider>
              <br></br>
              <p></p>
            </div>
          )
        } else {



          return (
            <div key={index}>

              <Stack direction="row" spacing={2}>
                <TextField fullWidth type="number" InputProps={{ inputProps: { min: 1, max: 9999999 } }} name="amount" required id="outlined-basic" label="Amount" variant="outlined" value={input.amount} onChange={event => handleFormChange(index, event)} />

                <MeasurmentSelect index={index} value={input.name} parentCallback={handleTypeChange} items={measurmentsItems}/>

                <IngredientSelect index={index} parentCallback={handleNameChange} items={ingredientsItems}/>

              </Stack>
              <br></br>
            </div>
          )
        }
      }))
    
  };
  }



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleClick2 = () => {
    setOpen2(true);
    setState({
      title: "",
      preparation: "",
      ingredients: [
        { name: "", amount: "", type: "" }
      ],
      meta: [],
      duration: 0,
      number: 0,
      owner: user.username,
      categories: [],
      note: '',
      img: '',
      imgName: ''
    })


  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen2(false);
    goHome();
  };


  const handleClose3 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen3(false);
  };

  const fileChangeHandler = (e) => {
    const allowed = ['jpg', 'jpeg', 'png', 'bmp', 'tiff', 'tif', 'webp']

    let split = e.target.files[0].name.split('.')
    console.log(split.length)
    let type = split[split.length - 1].toLowerCase()
    if (allowed.includes(type)) {
      setFileData(e.target.files[0]);
      setFileButton(e.target.files[0].name)
    }
    else {
      //console.log(type)
      e.target.files.value = ''
      setFileData('');
      setFileButton('FOTO')
      setOpen(true);
    }
  };


  const removeImage = (e) => {
    //e.target.files.value = ''
    setFileData('');
    setFileButton('FOTO')

  };



  const callbackFunction = (childData) => {

    setState(
      {
        title: state.title,
        preparation: state.preparation,
        ingredients: state.ingredients,
        meta: state.meta,
        duration: state.duration,
        number: state.number,
        owner: state.owner,
        categories: childData,
        note: state.note,
        img: state.img,
        imgName: state.imgName
      })
  }

  const callbackFunction3 = (childData) => {
    //this.setState({categories: childData})

    setState(
      {
        title: state.title,
        preparation: state.preparation,
        ingredients: state.ingredients,
        meta: childData,
        duration: state.duration,
        number: state.number,
        owner: state.owner,
        categories: state.categories,
        note: state.note,
        img: state.img,
        imgName: state.imgName
      })
  }

  const handleSumbit = async (e) => {
    e.preventDefault()

    ///console.log(state)

    const data = new FormData();
    const recipe = state
    data.append("file", fileData);
    data.append('name', 'test')

    let status = false

    const config = {
      headers: {
        authorization: 'Bearer ' + cookies['token']
      }
    };
    //console.log(fileData)
    if (fileData !== null && fileData !== undefined) {
      axios.post(BASE_URL + '/images/upload', data, config).then(response => {
        state.img = response.data.result
        state.imgName = response.data.name
        //console.log(response.data)
      }).catch(({ response }) => {
        setOpen3(true)
      }).finally(() => {
        axios.post(BASE_URL + '/recipes/add', recipe, config).then(response => {
          if (response.status === 200) {
            goHome()
            handleClick2()
          }

          //console.log(response)
        }).catch((err) => {
          console.log(err)
          setError(true)
          setOpen3(true)
        }).finally(() => {
          setLoading(false)
          status = true
          handleUpload(status)
        })
      });
    } else {
      axios.post(BASE_URL + '/recipes/add', recipe, config).then(response => {
        //console.log(response)
        if (response.status === 200) {
          goHome()
          handleClick2()
        }
      })
        .catch((err) => {
          setError(true)
          console.log(err)
          setOpen3(true)
        }).finally(() => {
          setLoading(false)
          status = true
          handleUpload(status)
        })
    }
  }

  const handleUpload = async (status) => {
    await sleep(1000);
    if (error === false && loading === false && status === true) {
      goHome()
      handleClick2()
    }
  }

  const goHome = async () => {
    await sleep(1000);
    navigate("/recipes");
  };


  const checkInputs = () => {
    var checkTitle = false;
    var checkPrep = false;
    var checkAmount = false;
    var checkIngr = false;
    var checkType = false;
    var checkCat = false;
    var checkNumber = false;
    var checkDuration = false;

    //console.log(token)
    if (state.title !== '') {
      checkTitle = true
    }
    if (state.preparation !== '') {
      checkPrep = true
    }
    for (var i = 0; i < state.ingredients.length; i++) {
      if (state.ingredients[i].amount !== '' && state.ingredients[i].amount !== 0) {
        checkAmount = true
      } else {
        checkAmount = false;
        break;
      }
    }
    for (var y = 0; y < state.ingredients.length; y++) {
      if (state.ingredients[y].name !== '' && state.ingredients[y].name !== 0) {
        checkIngr = true
      } else {
        checkIngr = false;
        break;
      }
    }
    for (var x = 0; x < state.ingredients.length; x++) {
      if (state.ingredients[x].type !== '' && state.ingredients[x].type !== 0) {
        checkType = true
      } else {
        checkType = false;
        break;
      }
    }
    if (state.number !== '' && state.number > 0) {
      checkNumber = true
    }
    if (state.duration !== '' && state.duration > 0) {
      checkDuration = true
    }
    if (state.categories !== '' && state.categories.length > 0) {
      checkCat = true
    }

    if (checkTitle && checkPrep && checkAmount && checkIngr && checkType && checkDuration && checkNumber && checkCat) {
      return true;
    } else {
      return false;
    }

  }
  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const renderForm = () => {

    return (

      <div>
        <Container sx={{ width: '95%' }} lg={{ width: '60%' }}>
          <Typography variant="h4">Neues Rezept</Typography>
          <p></p>
          <form onSubmit={handleSumbit} encType="multipart/form-data" >


            <TextField name="title" fullWidth required id="outlined-basic" label="Name" variant="outlined" onChange={(e) => setState(
              {
                title: e.target.value,
                preparation: state.preparation,
                ingredients: state.ingredients,
                meta: state.meta,
                duration: state.duration,
                number: state.number,
                owner: state.owner,
                categories: state.categories,
                note: state.note,
                img: state.img,
                imgName: state.imgName
              })} />
            <p></p>
            <TextField
              id="outlined-multiline-flexible"
              label="Zubereitung"
              multiline
              name="preparation"
              onChange={(e) => setState(
                {
                  title: state.title,
                  preparation: e.target.value,
                  ingredients: state.ingredients,
                  meta: state.meta,
                  duration: state.duration,
                  number: state.number,
                  owner: state.owner,
                  categories: state.categories,
                  note: state.note,
                  img: state.img,
                  imgName: state.imgName
                })}
              rows={16}
              variant="outlined"
              fullWidth required
              InputProps={{
                width: '30%',
              }}
            />

            <p></p>
            <Button
              color="primary"
              size="small"
              component="span"
              aria-label="add"
              variant="outlined"
              onClick={(e) => {
                addField0(e)
              }}
            >
              <AddIcon />Abschnitt
            </Button>
            <p></p>
            {renderIngredientInputs()}
            {/** 
<button onClick={addFields}>Add More..</button>
<button onClick={removeFields}>Remove</button>
*/}

            <Button
              color="primary"
              size="small"
              component="span"
              aria-label="add"
              variant="outlined"
              onClick={(e) => {
                addFieldx(e)
              }}
            >
              <AddIcon />Abschnitt
            </Button>
            <p></p>
            <IconButton variant="outlined" aria-label="plus" onClick={addFields} color="primary">  <AddCircleIcon sx={{ fontSize: 30 }} /> </IconButton>
            <IconButton variant="outlined" onClick={removeFields} aria-label="delete"><DeleteIcon color="primary" sx={{ fontSize: 30 }} /></IconButton>

            <p></p>



            <Stack direction="row" spacing={2}>
              <TextField name="duration" type="number" fullWidth required InputProps={{ inputProps: { min: 1 } }} label="Dauer (Min)" variant="outlined" onChange={(e) => setState(
                {
                  title: state.title,
                  preparation: state.preparation,
                  ingredients: state.ingredients,
                  meta: state.meta,
                  duration: e.target.value,
                  number: state.number,
                  owner: state.owner,
                  categories: state.categories,
                  note: state.note,
                  img: state.img,
                  imgName: state.imgName
                })} />

              <TextField name="number" type="number" fullWidth required InputProps={{ inputProps: { min: 1 } }} label="Personen/Portionen" variant="outlined" onChange={(e) => setState(
                {
                  title: state.title,
                  preparation: state.preparation,
                  ingredients: state.ingredients,
                  meta: state.meta,
                  duration: state.duration,
                  number: e.target.value,
                  owner: state.owner,
                  categories: state.categories,
                  note: state.note,
                  img: state.img,
                  imgName: state.imgName

                })} />

            </Stack>
            <p></p>
            <Stack direction="row" spacing={2}>
              <CategorieSelect parentCallback={callbackFunction} />
              <MetaSelect parentCallback={callbackFunction3} />
            </Stack>

            <p></p>
            <TextField
              id="outlined-multiline-flexible"
              label="Notiz"
              multiline
              name="note"
              onChange={(e) => setState(
                {
                  title: state.title,
                  preparation: state.preparation,
                  ingredients: state.ingredients,
                  meta: state.meta,
                  duration: state.duration,
                  number: state.number,
                  owner: state.owner,
                  categories: state.categories,
                  note: e.target.value,
                  img: state.img,
                  imgName: state.imgName
                })}
              rows={4}
              variant="outlined"
              fullWidth
              InputProps={{
                width: '30%',
              }}
            />

            <p></p>

            <label htmlFor="upload-photo">
              <input
                style={{ display: 'none' }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={fileChangeHandler}
                accept="image/*"
              />

              <Button
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="outlined"
              >
                <AddIcon />{fileButton}
              </Button>
              <IconButton variant="outlined" onClick={removeImage} aria-label="delete"><DeleteIcon color="primary" sx={{ fontSize: 30 }} /></IconButton>

              <br />
              <br />


            </label>

          </form>
        </Container>


        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Bitte w&auml;hle ein unterst&uuml;tztes Bildformat!
          </Alert>
        </Snackbar>
        <Snackbar open={open2} autoHideDuration={5000} onClose={handleClose2}>
          <Alert onClose={handleClose2} severity="success" sx={{ width: '100%' }}>
            Erfolgreich hochgeladen!
          </Alert>
        </Snackbar>
        <Snackbar open={open3} autoHideDuration={10000} onClose={handleClose3}>
          <Alert onClose={handleClose3} severity="error" sx={{ width: '100%' }}>
            Da ist etwas schief gelaufen!
          </Alert>
        </Snackbar>

      </div>

    )
  }


  if (checkInputs()) {
    //console.log(token)
    return (

      <>
        {renderForm()}
        <div>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            onClick={handleSumbit}
          >
            Absenden
          </Button>
          <p></p><p></p>
        </div>
      </>
    )
  } else {
    return (

      <>
        {renderForm()}

        <div>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled
            onClick={handleSumbit}
          >
            Absenden
          </Button>
          <p></p><p></p>
        </div>
      </>
    )
  }

}
