import React from 'react';

import useAuth from '../hooks/useAuth';


import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

import { UserContext } from '../hooks/UserContext';


import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { BASE_URL } from '../api'



export default function Profile() {

  document.title = 'Profile'
  const { changePw, error } = useAuth();
  const { user, isLoading } = React.useContext(UserContext);
  const handleLogin = async (e) => {
    e.preventDefault();
    let username = user.username;
    console.log({ username, password, newPassword, newPasswordConfirm })
    await changePw({
      username,
      password,
      newPassword,
      newPasswordConfirm
    }, 'change');
  }
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState();
  const [newPassword, setNewPassword] = React.useState();
  const [password, setPassword] = React.useState();
  const [count, setCount] = React.useState(0);

  const buttonCheck = () => {
    if (password !== (null || undefined || '') && newPassword !== (null || undefined || '') && newPasswordConfirm !== (null || undefined || '') && newPassword === newPasswordConfirm) {
      return (
        <Button variant="outlined" type="submit">&auml;ndere Passwort</Button>
      )
    } else {
      return (
        <Button variant="outlined" disabled type="submit">&auml;ndere Passwort</Button>
      )
    }


  }



  React.useEffect(() => {

    if (!isLoading) {

      fetch(BASE_URL + "/recipes/count/" + user.username)//"http://localhost:5000/recipes/count/"+user.username)
        .then(res => res.json())
        .then(
          (result) => {
            setCount(result);
          },
          (error) => {
            setCount(0);

          }
        )

    }

  })

  //console.log(user, isLoading);


  return (




    <Container>
      <br></br>
      <br></br>
      <Typography variant="h5"> Hallo {user.username}</Typography>
      <Typography variant="h5"> Anzahl deiner Rezepte:  {count}</Typography>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '50vh' }}
      >
        <Typography variant="h6">Passwort &auml;ndern:</Typography>
        <Grid item xs={3}>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleLogin}
          >

            <Stack direction="column" spacing={2}>

              <TextField id="outlined-basic" label="Passwort" variant="outlined" required type="password" onChange={e => setPassword(e.target.value)} />
              <TextField id="filled-basic1" label="Neues Passwort" variant="outlined" required type="password" onChange={e => setNewPassword(e.target.value)} />
              <TextField id="filled-basic" label="Wiederhole Passwort" variant="outlined" required type="password" onChange={e => setNewPasswordConfirm(e.target.value)} />
              <Collapse in={error}>
                <Alert open={error} severity="error">Versuch es noch einmal!</Alert>
              </Collapse>

              {buttonCheck()}

            </Stack>
          </Box>
        </Grid>

      </Grid>
    </Container>


  )
}