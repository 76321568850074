import * as React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { Link } from "react-router-dom";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import { BASE_URL } from '../api'

export default function RecipeCard(props) {


  function image(img) {
    if (img !== '' && img !== undefined) {
      return (
        <div >
          {/**<img width='100%'  src={'/images/'+img}></img>*/}


          <CardMedia component="img"
            height="160"
            //image={'/images/'+img}
            image={BASE_URL + '/images/download/' + img}
            alt="image" />
        </div>
      )
    }
  }
  return (




    <Grid item xs={3} key={props.recipe._id}   >

      <Card elevation={6} >
        <CardActionArea component={Link} to={'/recipe/' + props.recipe._id}>

          {image(props.recipe.img)}

          <CardContent>




            <Container xs={{ m: '5' }}>

              <Typography variant="h5" >
                {props.recipe.title}
              </Typography>

              <p></p>
              <Grid container
                spacing={3}
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <Grid item>
                  <Typography>
                    {props.recipe.duration} Min.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {props.recipe.number} P.
                  </Typography>
                </Grid>
              </Grid>
            </Container>



          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>

  );
}



