import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import { BASE_URL } from '../api'

export default function Tags(props) {

  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [,, setValue] = React.useState([]);
  React.useEffect(() => {
    fetch(BASE_URL + "/config/ingredients/")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    //return <div>Loading...</div>;
    return <CircularProgress />;
  } else {
    return (

      <Autocomplete
        fullWidth

        multiple
        onChange={(event, newValue) => {
          setValue(newValue);
          props.callback(newValue)
          console.log(newValue)
        }}

        id="zutaten-search"
        options={items}
        getOptionLabel={(option) => option._id}
        defaultValue={props.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Zutaten" //handle required mark(*) on label 
            
            variant="standard"

            //label="Kategorie *"
            placeholder="Zutaten"
          />
        )}

      />


    );
  }




}

