import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../api'

export default function Tags(props) {
  let navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [items, setItems] = React.useState([]);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (value !== undefined && value !== '' && value.length >= 1) {
      fetch(BASE_URL + "/recipes/all/search/" + value + "/")
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(result)
            setIsLoaded(true);
            setItems(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }
  }, [value])


  const searchHandler = (e) => {
    e.preventDefault()
    if (typeof value == 'string') {
      navigate('/search/' + value)
    } else {
      navigate('/recipe/' + value._id)
    }
    //navigate('/search/'+searchValue)
    //console.log(value)
  }



  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    //return <div>Loading...</div>;
    return <CircularProgress />;
  } else {
    return (

      <Autocomplete

        //onKeyDown={(e) => console.log(e)}

        fullWidth
        filterOptions={(x) => x}
        freeSolo
        name='search'
        onChange={(event, newValue) => {
          //console.log(event)
          if (event.keyCode === 13) {
            searchHandler(event)
          }
          event.preventDefault();
          setValue(newValue);
          props.callback(newValue)
          //console.log(items)
          if (items.length === 1) {
            navigate('/recipe/' + items[0]._id)
            //searchHandler(event)
          }
          if (typeof newValue === 'string') {
            navigate('/search/' + newValue)
          } else {
            navigate('/recipe/' + newValue._id)
          }
          //console.log(newValue)
        }}
        onInputChange={(event, newValue) => {

          //console.log(event)
          if (event.keyCode === 13) {
            searchHandler(event)
          } else if (event.type === 'click') {
            //console.log(items)
            searchHandler(event)
          }

          event.preventDefault();
          setValue(newValue);
          props.callback(newValue)
          //console.log(newValue)
        }}

        id="tags-standard"
        options={items ? items : ' '}
        getOptionLabel={(option) => option.title || ''}
        //defaultValue={props.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Suche' //handle required mark(*) on label 
            //required
            //variant="outlined"
            variant="standard"
            //label="Kategorie *"
            placeholder="Suchbegriff eingeben"
          />
        )}

      />


    );
  }




}

