import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function Tags(props) {

    return (

      <Autocomplete
        fullWidth
        name='type'
        onChange={(event, newValue) => {
          //setValue(newValue);
          props.parentCallback(props.index, newValue)
        }}

        id="tags-standard"
        options={props.items}
        getOptionLabel={(option) => option || ''}
        defaultValue={props.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Einheit' //handle required mark(*) on label 
            required
            variant="outlined"

            //label="Kategorie *"
            placeholder="Einheit"
          />
        )}

      />


    );
  }
