import './App.css';
import React from 'react'

import Home from './pages/Home'
import AllRecipes from './pages/AllRecipes'
import Upload from './pages/Upload'
import Category from './pages/Category'
import Meta from './pages/Meta'
import Recipe from './pages/Recipe'
import Admin from './pages/Admin'
import Search from './pages/Search'
import Find from './pages/Find'
import Login from './pages/Login'
import Profile from './pages/Profile'
import MenuBar from './components/MenuBar'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Ingredient from './pages/Ingredient';
import { UserContext } from './hooks/UserContext';
import useFindUser from './hooks/useFindUser';
import PrivateRoute from './pages/PrivateRoute';
import Edit from './pages/Edit';
function App() {

  const { user, setUser, isLoading } = useFindUser();

  return (
    <Router>
      <UserContext.Provider value={{ user, setUser, isLoading }}>
        <MenuBar></MenuBar>

        <div className="App" >
          <p></p>

          <Routes>
            <Route exact path='/' element={< Home />}></Route>
            <Route exact path='/recipes' element={< AllRecipes />}></Route>


            <Route exact path='/upload' element={<PrivateRoute />}>
              <Route exact path='/upload' element={<Upload />} />
            </Route>

            <Route exact path='/admin' element={<PrivateRoute />}>
              <Route exact path='/admin' element={<Admin />} />
            </Route>
            <Route exact path='/edit/:id' element={<PrivateRoute />}>
              <Route exact path='/edit/:id' element={<Edit />} />
            </Route>
            <Route exact path='/profile' element={<PrivateRoute />}>
              <Route exact path='/profile' element={<Profile />} />
            </Route>

            <Route exact path='/category/:cat' element={< Category />}></Route>
            <Route exact path='/meta/:meta' element={< Meta />}></Route>
            <Route exact path='/ingredient/:ingr' element={< Ingredient />}></Route>
            <Route exact path='/recipe/:id' element={< Recipe />}></Route>
            <Route exact path='/login' element={< Login />}></Route>
            <Route exact path='/search/:query' element={< Search />}></Route>
            <Route exact path='/find/:query' element={< Find />}></Route>
          </Routes>

        </div>
      </UserContext.Provider>
    </Router>
  );
}

export default App;
